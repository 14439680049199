<template>
<!-- 统计 -->
  <div class="statisticsContainer">
统计
  </div>
</template>

<script>
export default {
name:'statisticsContainer'
}
</script>

<style>

</style>